import { createSelector, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
// Define the initial state using that type
const initialState = {
  customizeCode: true,
  blockCheckoutRuleBanner: true,
  abandonedCheckout: true, //
  bannerWhitelist: true, //
  bannerVisitorAnalyticWarning: true,
  visitorAnalyticWarningTable: true, //
  bannerVisitorAnalyticProgress: true,
  bannerUpdateVersion: true,
  bannerCustomCSS: true,
  showThingsToDoNext: true,
  showBannerWaterMark: true,
  fraudOrderBanner: true, //
  bannerReviewSetUpApp: true,
  bannerUpgradeShopifyPlus: true, //
  noticeEmbeddedApp: true,
  bannerLimitRuleSetting: true, //
  bannerLimitRuleBlockAndRedirect: true, //
  bannerBlockCheckoutAccess: true, //
  showFlashSaleCard: true, //
  checkoutRulesBanner: true, //
  referCheckoutRulesBanner: true,
  bookACallBanner: true,
  contactSupportFromVA: true, //
  bannerPromotionSynctrack: true, //
  bannerWarningDemoDataVA: true, //
  bannerFlashSaleEndOfYear: true, //
  bannerPromotionFlashSale: true, //
  bannerDispute: true, //
  hideShippingMethod: true, //
  hidePaymentMethod: true, //
  promotePareto: true, //
  recommendedApp: true, //
};

const bannerSlice = createSlice({
  name: 'banner',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    handleVisibleBanner: (state, action: PayloadAction<{ key: keyof typeof initialState; value: boolean }>) => {
      state[action.payload.key] = action.payload.value;
    },
  },
});

// Other code such as selectors can use the imported `RootState` type
export const bannerSelector = createSelector(
  (state: RootState) => state.banner,
  (state) => state,
);

export const { handleVisibleBanner } = bannerSlice.actions;

export default bannerSlice;
